<template>
   <div id="main-wrapper">
      <!-- <Container/> -->
      <div class="mechantWooPage">
         <section class="blockElement">
            <div class="container">
               <div class="row justify-content-center">
                  <div class="col-12 col-md-10 text-center">
                     <h1 class="mb-0">{{contentlist.merchant_tools_woo_content1}}</h1>
                     <p class="d-block mt-2 mt-md-4">{{contentlist.merchant_tools_woo_content2}}</p>
                     <div class="d-block text-center mt-2 mt-md-4"><a href="/assets/js/wc-capital-wallet-payment-gateway.zip" class="themeBtn medium" download>{{contentlist.merchant_tools_woo_content3}}</a></div>
                  </div>
               </div>
            </div>
         </section>
         <section class="blockElement pt-0 pt-lg-4">
            <div class="container">
               <div class="row justify-content-between align-items-center">
                  <div class="col-12 col-md-6">
                     <img src="/assets/images/about-woocommerce.webp" alt="about woocommerce" title="about woocommerce" class="img-fluid">
                  </div>
                  <div class="col-12 col-md-6 ps-2 ps-xl-5 text-center text-md-start">
                     <h2 class="mb-0 pb-2">{{contentlist.merchant_tools_woo_content4}}</h2>
                     <p class="d-block mb-0">{{contentlist.merchant_tools_woo_content5}}<br>
                        {{contentlist.merchant_tools_woo_content6}}
                        </p>
                  </div>
               </div>
            </div>
         </section>
         <section class="blockElement lightgreybg">
            <div class="container">
               <h2 class="mb-4 text-center">{{contentlist.how_it_works_content}}</h2>
               <Carousel class="mechantCarousel">
                  <Slide :key="slide">
                     <div class="carousel__item">
                        <div class="mechantCarouselBox">
                           <div class="row">
                              <div class="col-12 col-lg-6 pe-4">
                                 <img src="/assets/images/merchant-portal.webp" alt="merchant portal" title="merchant portal" class="img-fluid">
                              </div>
                              <div class="col-12 col-lg-6 mt-3 mt-lg-0 text-center text-sm-start">
                                 <div class="d-block d-sm-flex align-items-start align-items-sm-center">
                                    <span class="mechantCarouselNumber primarycolor semibold rounded-pill d-flex justify-content-center align-items-center">{{contentlist.one_content}}</span>
                                    <h6 class="ms-0 ms-sm-3 mb-0 pt-3 pt-sm-0">{{contentlist.merchant_tools_woo_content7}}</h6>
                                 </div>
                                 <p class="mt-3">{{contentlist.merchant_tools_woo_content8}}</p>
                                 <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="textLink">{{contentlist.merchant_tools_woo_content9}}</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Slide>
                  <Slide :key="slide">
                     <div class="carousel__item">
                        <div class="mechantCarouselBox">
                           <div class="row flex-row-reverse">
                              <div class="col-12 col-lg-6 ps-4">
                                 <img src="/assets/images/api-key.webp" alt="api key" title="api key" class="img-fluid">
                              </div>
                              <div class="col-12 col-lg-6 mt-3 mt-lg-0 text-center text-sm-start">
                                 <div class="d-block d-sm-flex align-items-start align-items-sm-center">
                                    <span class="mechantCarouselNumber primarycolor semibold rounded-pill d-flex justify-content-center align-items-center">{{contentlist.two_content}}</span>
                                    <h6 class="ms-0 ms-sm-3 mb-0 pt-3 pt-sm-0">{{contentlist.merchant_tools_woo_content10}}</h6>
                                 </div>
                                 <p class="mt-3">{{contentlist.merchant_tools_woo_content11}}</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Slide>
                  <Slide :key="slide">
                     <div class="carousel__item">
                        <div class="mechantCarouselBox">
                           <div class="row">
                              <div class="col-12 col-lg-6 pe-4">
                                 <img src="/assets/images/plugin-installation.webp" alt="Download WooCommerce Plugin" title="Download WooCommerce Plugin" class="img-fluid">
                              </div>
                              <div class="col-12 col-lg-6 mt-3 mt-lg-0 text-center text-sm-start">
                                 <div class="d-block d-sm-flex align-items-start align-items-sm-center ">
                                    <span class="mechantCarouselNumber primarycolor semibold rounded-pill d-flex justify-content-center align-items-center">{{contentlist.three_content}}</span>
                                    <h6 class="ms-0 ms-sm-3 mb-0 pt-3 pt-sm-0">Plugin Installation Instructions</h6>
                                 </div>
                                 <p class="mt-3">Open the Plugins Dropdown Menu</p>
                                 <p class="mt-3">Locate and choose the "Upload Plugin" option.</p>
                                 <p class="mt-3">Complete the process by clicking the "Install Now" button to install the plugin.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Slide>
                  <Slide :key="slide">
                     <div class="carousel__item">
                        <div class="mechantCarouselBox">
                           <div class="row flex-row-reverse">
                              <div class="col-12 col-lg-6 ps-4">
                                 <img src="/assets/images/plugin-activation.webp" alt="api key" title="api key" class="img-fluid">
                              </div>
                              <div class="col-12 col-lg-6 mt-3 mt-lg-0 text-center text-sm-start">
                                 <div class="d-block d-sm-flex align-items-start align-items-sm-center ">
                                    <span class="mechantCarouselNumber primarycolor semibold rounded-pill d-flex justify-content-center align-items-center">{{contentlist.four_content}}</span>
                                    <h6 class="ms-0 ms-sm-3 mb-0 pt-3 pt-sm-0">Plugin Activation</h6>
                                 </div>
                                 <p class="mt-3">After completing the installation, Scroll through the list of installed plugins to locate Capital Wallet’s plugin</p>
                                 <p class="mt-3">Click on the "Activate" button, and you'll typically see a confirmation message indicating that the plugin has been successfully activated.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Slide>
                  <Slide :key="slide">
                     <div class="carousel__item">
                        <div class="mechantCarouselBox">
                           <div class="row">
                              <div class="col-12 col-lg-6 pe-4">
                                 <img src="/assets/images/plugin-configure.webp" alt="Enter API Secret Key To Plugin Settings" title="Enter API Secret Key To Plugin Settings" class="img-fluid">
                              </div>
                              <div class="col-12 col-lg-6 mt-3 mt-lg-0 text-center text-sm-start">
                                 <div class="d-block d-sm-flex align-items-start align-items-sm-center ">
                                    <span class="mechantCarouselNumber primarycolor semibold rounded-pill d-flex justify-content-center align-items-center">{{contentlist.five_content}}</span>
                                    <h6 class="ms-0 ms-sm-3 mb-0 pt-3 pt-sm-0">Configure Plugin and use</h6>
                                 </div>
                                 <p class="mt-3">After activation, you need to configure the plugin with your API keys and Merchant ID and also set up your payment settings.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Slide>
                  <template #addons>
                     <Navigation />
                     <Pagination />
                  </template>
               </Carousel>
                <div class="text-center mt-2 mt-md-4">
                  <a href="/assets/js/CapitalWallet Plugin Installation.pdf" class="themeBtn medium" target="_blank()">Plugin Installation Guide</a>
                </div>
            </div>
         </section>
         <section class="blockElement globalLeaders">
            <div class="container">
               <h2 class="mb-5 text-center">{{contentlist.merchant_tools_woo_content37}}</h2>
               <div class="row justify-content-center">
                  <div class="col-12 col-md-4 text-center">
                     <div class="globalLeadersBox m-auto">
                        <img src="/assets/images/global-benefit-one.webp" alt="Benefit 1" title="Benefit 1" height="90" class="img-fluid">
                        <h6 class="mb-0 mt-3">{{contentlist.merchant_tools_woo_content38}}</h6>
                        <p class="d-block mt-2">{{contentlist.merchant_tools_woo_content39}}</p>
                     </div>
                  </div>
                  <div class="col-12 col-md-4 text-center">
                     <div class="globalLeadersBox m-auto">
                        <img src="/assets/images/global-benefit-two.webp" alt="Benefit 2" title="Benefit 2" height="90" class="img-fluid">
                        <h6 class="mb-0 mt-3">{{contentlist.merchant_tools_woo_content40}}</h6>
                        <p class="d-block mt-2">{{contentlist.merchant_tools_woo_content41}}</p>
                     </div>
                  </div>
                  <div class="col-12 col-md-4 text-center">
                     <div class="globalLeadersBox m-auto">
                        <img src="/assets/images/global-benefit-three.webp" alt="Benefit 3" title="Benefit 3" height="90" class="img-fluid">
                        <h6 class="mb-0 mt-3">{{contentlist.merchant_tools_woo_content42}}</h6>
                        <p class="d-block mt-2">{{contentlist.merchant_tools_woo_content43}}</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
      <!-- <Footer /> -->
      <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 text-center primarybgSection">
                  <div class="criptoIcon">
                     <span class="left-tp one"><img src="/assets/images/onHover-2.webp" alt="Icon" title="Crypto 1" width="77" height="90" class="img-fluid"></span>
                     <span class="left-tp tow"><img src="/assets/images/onHover-1.webp" alt="Icon" title="Crypto 2" width="98" height="98" class="img-fluid"></span>
                     <span class="left-tp three"><img src="/assets/images/onHover-3.webp" alt="Icon" title="Crypto 3" width="56" height="89" class="img-fluid"></span>
                     <span class="left-tp four"><img src="/assets/images/onHover-4.webp" alt="Icon" title="Crypto 4" width="87" height="86" class="img-fluid"></span>
                  </div>
                  <div class="content position-relative">
                     <h2 class="whitecolor">{{contentlist.get_started_content}}</h2>
                     <p class="whitecolor regular mb-0">{{contentlist.cta_three_content2}}</p>
                     <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="whiteBtn medium mt-4">{{contentlist.register_now_content}}</a>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>
<script>
import { commonAllmixins } from '@/plugins/commonAll'   //anamica
import "vue3-carousel/dist/carousel.css";
   import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
   
   export default {
      mixins: [ commonAllmixins],   //anamica
     data() {
       return {
         settings: {
            itemsToShow: 1,
            itemsToScroll:1,
            snapAlign: 'start',
         },
       // breakpoints are mobile first
       // any settings not specified will fallback to the carousel settings
       breakpoints: {
         // 700px and up
         700: {
           itemsToShow: 1,
           itemsToScroll:1,
           snapAlign: 'start',
         },
         // 1024 and up
         1024: {
           itemsToShow: 1,
           itemsToScroll:1,
           snapAlign: 'start',
         },
       },
       };
     },
     components: {
       Carousel,
       Slide,
       Pagination,
       Navigation,
     },
   };
</script>